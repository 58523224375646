import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { removeSiteEventOpts, stopManagingEventOpts } from '../../events';
import useGaEvent from '../../hooks/useGAEvent';
import { removeSinglePad } from '../../store/actions/padsAction';
import Button from '../../uiKitComponents/button';
import Col from '../../uiKitComponents/col';
import Form from '../../uiKitComponents/form';
import Input from '../../uiKitComponents/input';
import notification from '../../uiKitComponents/notification';
import Popconfirm from '../../uiKitComponents/popconfirm';
import Row from '../../uiKitComponents/row';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import Modal from '../../uiKitComponents/modal';
import { QrcodeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const StopManaging = ({ pad_id, disabled, setDisabled }) => {
  const [visible, toggleVisible] = useToggle(false);
  const [loading, toggleLoading] = useToggle(false);
  const history = useHistory();
  const dispatch = useDispatch();

  // GA events
  const stopManagingEvent = useGaEvent(stopManagingEventOpts);

  const onConfirmHandler = async () => {
    setDisabled(true);
    toggleLoading();

    try {
      await api.pad.removeOwner(pad_id);
      await removeSinglePad(Number(pad_id))(dispatch);
      notification.success({
        message: 'Success',
        description: 'You are no longer the owner of this site',
      });
      stopManagingEvent()();
      history.push('/my-sites');
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
      setDisabled(false);
      toggleLoading();
    }
  };

  return (
    <Popconfirm
      title="Are you sure you want to stop managing this site?"
      okText="Yes"
      visible={visible}
      onConfirm={onConfirmHandler}
      okButtonProps={loading}
      onVisibleChange={(v) => toggleVisible(v)}
    >
      <Button
        type="primary"
        className="mr-4"
        disabled={disabled}
        onClick={toggleVisible}
      >
        Stop managing this site
      </Button>
    </Popconfirm>
  );
};

const RemoveSite = ({ pad_id, disabled, setDisabled }) => {
  const [visible, toggleVisible] = useToggle(false);
  const [loading, toggleLoading] = useToggle(false);
  const [modalVisible, toggleModalVisible] = useToggle(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // GA events
  const removeSiteEvent = useGaEvent(removeSiteEventOpts);

  const onFinishHandler = async ({ reason }) => {
    setDisabled(true);
    toggleLoading();

    try {
      await api.pad.delete(pad_id, reason);
      await removeSinglePad(Number(pad_id))(dispatch);
      notification.success({
        message: 'Success',
        description:
          'Your request to remove this site has been successfully sent',
      });
      removeSiteEvent()();
      history.push('/my-sites');
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
      toggleLoading();
      setDisabled(false);
    }
  };

  const toggleConfirmationModal = () => {
    if (visible) {
      toggleVisible(false);
      toggleModalVisible(true);
    } else {
      toggleModalVisible(false);
    }
  };

  return (
    <div>
      <Popconfirm
        title="Are you sure to delete this site?"
        okText="Yes"
        visible={visible}
        onConfirm={toggleConfirmationModal}
        okButtonProps={loading}
        onVisibleChange={(v) => toggleVisible(v)}
      >
        <Button type="primary" disabled={disabled} onClick={toggleVisible}>
          Remove this site from Helipaddy
        </Button>
      </Popconfirm>
      <Modal
        visible={modalVisible}
        confirmLoading={loading}
        okButtonProps={{ type: 'default' }}
        cancelButtonProps={{ type: 'primary' }}
        okText="Send feedback"
        cancelText="Actually, I changed my mind"
        onOk={form.submit}
        onCancel={toggleConfirmationModal}
        wrapClassName="site-view_confirm-modal"
      >
        <p>
          Please tell us why you no longer wish to have your site listed on
          Helipaddy - we promise to listen!
        </p>
        <Form form={form} onFinish={onFinishHandler}>
          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                message:
                  'Please provide a reason for deleting your site from Helipaddy',
              },
            ]}
          >
            <Input.TextArea
              placeholder="I'd like to remove my site from Helipaddy because..."
              autoSize
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

const ExtraActions = ({ pad_id, pad }) => {
  const [disabled, setDisabled] = useState(false);

  return (
    <div className="site-view_extra-actions">
      {pad?.is_claim_approved && (
        <Row
          gutter={[16, 16]}
          justify="end"
        >
          {pad?.is_owned_by_current_user && (
            <Col
              span={10}
              sm={10}
              xs={24}
            >
              <StopManaging
                pad_id={pad_id}
                disabled={disabled}
                setDisabled={setDisabled}
              />
            </Col>
          )}
          <Col
            span={14}
            sm={14}
            xs={24}
          >
            <RemoveSite
              pad_id={pad_id}
              disabled={disabled}
              setDisabled={setDisabled}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

const RequestHiscCode = ({ hiscSlice, icao }) => {
  const { pad_id } = useParams();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const hiscCodeRequestHandler = () => {
    setLoading(true);
    const { 'hisc-code': hisc } = form.getFieldsValue();
    api.pad
      .getRrequestHiscCode(parseInt(pad_id, 10), hisc)
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'Your request has been successfully sent',
        });
        form.resetFields();
        setIsModalOpen(false);
      })
      .catch((error) => {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const convertFirstFourLettersToUpperCase = (e) => {
    const { value } = e.target;
    if (value.length > 4) {
      return;
    }
    const newValue = value.toUpperCase();
    form.setFieldsValue({ 'hisc-code': newValue });
  };

  return (
    <>
      {icao === null && (
        <Tooltip
          title="Requesting a personalised code is optional. If we don't hear from you, we will allocate one. Helipaddy International Site Codes are unique and help pilots locate you in navigation systems."
        >
          <Button
            type="secondary"
            className="mb-3 p-1 ml-2 button-link large"
            icon={<QrcodeOutlined />}
            onClick={showModal}
          >
            Request personalised HISC code
          </Button>
        </Tooltip>
      )}

      <Modal
        visible={isModalOpen}
        title="Request personalised HISC code"
        footer={null}
        onCancel={handleCancel}
        className="direction-relative"
        width={900}
      >
        <p className="mb-2">
          The Helipaddy International Site Code is your unique identifier
          assigned to this specific landing location. All sites are allocated a
          code by Helipaddy. However, you can request a 6 letter code here if
          you have a preferred one. Eg MYSITE. Not case sensitive, digits
          allowed.
        </p>
        <p className="mb-2">
          Requesting a personalised code is optional. If we don&lsquo;t hear
          from you, we will eventually allocate one. We are actively encouraging
          avionics software developers to adopt the codes in their navigation
          systems.
        </p>
        <Form
          className="pad-form"
          form={form}
          layout="vertical"
          onFinish={hiscCodeRequestHandler}
          initialValues={{ 'hisc-code': hiscSlice || '' }}
        >
          <Row gutter={[5, 5]}>
            <Col span={12} xs={24} sm={12}>
              <Form.Item
                name="hisc-code"
                rules={[
                  {
                    required: true,
                    message: 'Please enter HISC',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value === hiscSlice) {
                        return Promise.reject(
                          new Error(
                            'New HISC code cannot be same as old HISC Code',
                          ),
                        );
                      }
                      if (!value || /^[A-Z]{4}[0-9a-zA-Z]{2}$/.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('ex: HISC0a or HISC9A'));
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="ex: HISC0a"
                  onChange={convertFirstFourLettersToUpperCase}
                />
              </Form.Item>
            </Col>
            <Col span={6} xs={12} sm={6} md={6}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%', maxWidth: 300 }}
                disabled={loading}
              >
                Request {loading && '...'}
              </Button>
            </Col>
            <Col span={6} xs={12} sm={6} md={6}>
              <Button
                type="secondary"
                className="secondary-btn"
                onClick={handleCancel}
                style={{ width: '100%', maxWidth: 300 }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

StopManaging.propTypes = {
  pad_id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

RemoveSite.propTypes = {
  pad_id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

ExtraActions.propTypes = {
  pad_id: PropTypes.string.isRequired,
  pad: PropTypes.object,
};

ExtraActions.defaultProps = {
  pad: {},
};

RequestHiscCode.propTypes = {
  hiscSlice: PropTypes.string,
  icao: PropTypes.string,
};

export default ExtraActions;
export { RequestHiscCode };
